import React, { useState, useEffect, useRef } from "react";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Tooltip, Button } from "antd";
import { getDataDoc } from "../../database";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import AddElev from "./AddElev";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../database/firebase";
import { testSlice } from "../../redux/store";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Card, Grid } from "antd";
import { collection, query, where, getDocs, or } from "firebase/firestore";
import { openErrorNotification } from "../../Components/Notifications/errorNotification";
import { useSelector } from "react-redux";
const { actions } = testSlice;
const { GET_LOADING } = actions;
const { Meta } = Card;
const { Search } = Input;
function normalizeString(str) {
  if (!str) return "";
  return str
    .normalize("NFD") // Normalizează string-ul la forma decompusă
    .replace(/[\u0300-\u036f]/g, "") // Elimină diacriticele
    .toLowerCase(); // Transformă în litere mici
}
function ProfileElevi({ open, setOpen }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [elevi, setElevi] = useState([]);
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const [index, setIndex] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [space, setSpace] = useState(30);
  const [cardWith, setCardWith] = useState(300);
  const ref = useRef();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const onSearch = async (value) => {
    ref.current.focus();
    const arr = value.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    const names = str2.split(" ");

    const q = query(collection(db, "elevi"));
    const normalizedSearchString = normalizeString(value);
    if (!normalizedSearchString) {
      setFilteredData([]);
      return;
    }
    try {
      const array = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (!doc.data()) return;
        const normalizedNumeFamilie = normalizeString(
          "" + doc.data().numeDeFamilie
        );
        const normalizedPrenume = normalizeString("" + doc.data().prenume);
        const normalizedCnp = normalizeString("" + doc.data().cnp || "");
        const normalizedEmail = normalizeString("" + doc.data().email || "");
        const normalizedId = normalizeString("" + doc.data().id);
        if (
          normalizedNumeFamilie &&
          normalizedPrenume &&
          (normalizedNumeFamilie?.includes(normalizedSearchString) ||
            normalizedPrenume?.includes(normalizedSearchString) ||
            normalizedCnp?.includes(normalizedSearchString) ||
            normalizedEmail?.includes(normalizedSearchString) ||
            normalizedId?.includes(normalizedSearchString))
        )
          array.push({
            id: doc?.id,
            numeDeFamilie: doc.data().numeDeFamilie,
            prenume: doc.data().prenume,
            adresaEmail: doc.data().adresaEmail,
            cnp: doc.data().cnp,
          });
      });
      setFilteredData(array);
    } catch (err) {
      console.log(err);
      openErrorNotification("nu a mers cautarea");
    }
  };

  const getData = async () => {
    let dataClass = await getDataDoc("claseData", "faraclasa");

    setElevi(dataClass?.elevi || []);
  };
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    getData();
    const unsub = onSnapshot(doc(db, "claseData", "faraclasa"), (doc) => {
      getData();
    });
  }, []);
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    const newWidth = window.innerWidth;
    if (screens.xl === true) {
      setCardWith(newWidth / 5.5);
      setSpace(30);
    } else if (screens.lg === true) {
      setSpace(200);
      setCardWith(newWidth / 3);
    } else if (screens.md === true) {
      setCardWith(newWidth / 2.6);
      setSpace(200);
    } else if (screens.sm === true) {
      setCardWith(newWidth / 1.3);

      setSpace(400);
    } else if (screens.xs === true) {
      setCardWith(newWidth / 1.3);

      setSpace(200);
    }
  }, [screens]);

  return (
    <div>
      <Modal
        title="Cauta elev"
        open={open}
        footer={<></>}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Search placeholder="" onSearch={onSearch} enterButton />

        <div
          className="dataResult"
          tabIndex={0}
          ref={ref}
          onKeyDown={(e) => {
            e.preventDefault();

            if (e.key === "ArrowUp") {
              if (index - 1 === -1) setIndex(filteredData.length - 1);
              else setIndex(Math.abs((index - 1) % filteredData.length));
            }
            if (e.key === "ArrowDown") {
              setIndex((index + 1) % filteredData.length);
            }
            if (e.key === "Enter" && filteredData[index]?.id)
              navigate("/elev/" + filteredData[index]?.id);
          }}
        >
          {filteredData.slice(0, 10).map((value, indexx) => {
            return (
              <div
                className="dataItem"
                key={value?.id}
                onClick={() => {
                  navigate("/elev/" + value?.id);
                }}
                style={
                  indexx === index
                    ? {
                        border: "1px solid black",
                        backgroundColor: "dodgerblue",
                        color: "white",
                      }
                    : { border: "1px solid black" }
                }
                id="dataItem"
                onMouseEnter={() => setIndex(indexx)}
              >
                <h3>
                  {value.prenume} {value.numeDeFamilie}
                </h3>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}

export default ProfileElevi;
