import {
  UserOutlined,
  ScheduleOutlined,
  CarryOutOutlined,
  SmileOutlined,
  InfoCircleOutlined,
  BookOutlined,
  TrophyOutlined,
  UsergroupAddOutlined,
  ReadOutlined,
  ContainerOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { useWindowSize } from "@react-hook/window-size";
import ProfileElevi from "../Pages/Admin/ProfileElevi";
import { Breadcrumb, Layout, Menu, theme, Image } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import Chats from "../Pages/Chats/View";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const NavbarElev = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const user = useSelector((state) => state.user);
  const [width, height] = useWindowSize();
  const [open, setOpen] = useState(false);
  const [addElev, setAddElev] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const items = [
    getItem("Orar", "", <ScheduleOutlined />),

    getItem("Anunțuri", "anunturi", <CarryOutOutlined />),
    getItem("Profesori", "profesori", <UserOutlined />),
    getItem("Clase", "clase", <ReadOutlined />),
    getItem("Elevi", "profile-elevi", <UsergroupAddOutlined />, [
      { label: "Cauta elev", key: "search-elev" },
    ]),
    getItem("Condica", "condica", <BookOutlined />),
    getItem("Feedback", "feedback", <TrophyOutlined />),
    getItem("Statistici", "statistici", <LineChartOutlined />),
    getItem("Ajutor", "ajutor", <InfoCircleOutlined />),
    getItem("Profil", "profil", <SmileOutlined />),
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      breakpoint="lg"
      collapsedWidth={width <= 600 ? 0 : 70}
      style={
        window.screen.width < 750
          ? { position: "absolute", height: "100vh", zIndex: 10 }
          : {}
      }
    >
      <Chats open={open} setOpen={setOpen} />
      <ProfileElevi open={openSearch} setOpen={setOpenSearch} />
      <div
        style={{ width: "200px", display: "flex", justifyContent: "center" }}
      >
        {" "}
        {collapsed === false && (
          <Image
            width={100}
            height={100}
            src={process.env.REACT_APP_LOGO}
            preview={false}
            onClick={() => {
              navigate("/");
            }}
          />
        )}
      </div>

      <Menu
        onClick={(e) => {
          if (e.key === "search-elev") {
            setOpenSearch(true);
            return;
          }
          if (e.key === "mesaje") setOpen(true);
          else navigate("/" + e.key);
        }}
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
};
export default NavbarElev;
