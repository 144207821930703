import React, { useEffect, useState } from "react";
import { Button, Select, TimePicker } from "antd";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../database/firebase";
import { DatePicker, Space, Input } from "antd";
import dayjs from "dayjs";
import { deleteDataDoc, updateDocDatabase } from "../../database";
import { Background } from "devextreme-react/range-selector";
const format = "HH:mm";
function Concedii() {
  const { RangePicker } = DatePicker;
  const [concedii, setConcedii] = useState();
  const [prof, setProf] = useState();
  const [startDate, setStartDate] = useState();
  const [stringDates, setStringDates] = useState(["00:00", "23:59"]);
  const [text, setText] = useState("CONCEDIU MEDICAL");
  const [profi, setProfesori] = useState(true);
  const [administrator, setAdministrator] = useState(true);
  const [concediuOdihna, setConcediuOdihna] = useState(true);
  const [concediuMedical, setConcediuMedical] = useState(true);

  const [column, setColumn] = useState({
    columnName: "interval",
    order: "descending",
  });
  const [endDate, setEndDate] = useState();
  const profesori = useSelector((state) => state.profesori);
  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "concedii"));

    let array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      array.push({
        ...doc.data(),
        startDate: new Date(doc.data().startDate),
        endDate: new Date(doc.data().endDate),
      });
    });
    setConcedii(array);
  };

  useEffect(() => {
    fetchData();
    const unsub3 = onSnapshot(collection(db, "concedii"), (doc) => {
      fetchData();
    });
    return unsub3;
  }, []);
  const formatDate = (today) => {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm;
  };
  const sortColumns = () => {
    let newArray = [...(concedii || [])];
    if (column.columnName === "profesor" && column.order === "ascending") {
      newArray.sort((a, b) =>
        profesori.find((p) => p.id === a.prof)?.numeDeFamilie +
          " " +
          profesori.find((p) => p.id === a.prof)?.prenume <
        profesori.find((p) => p.id === b.prof)?.numeDeFamilie +
          " " +
          profesori.find((p) => p.id === b.prof)?.prenume
          ? -1
          : 1
      );
    }
    if (column.columnName === "profesor" && column.order === "descending") {
      newArray.sort((a, b) => {
        return profesori.find((p) => p.id === b.prof)?.numeDeFamilie +
          " " +
          profesori.find((p) => p.id === b.prof)?.prenume <
          profesori.find((p) => p.id === a.prof)?.numeDeFamilie +
            " " +
            profesori.find((p) => p.id === a.prof)?.prenume
          ? -1
          : 1;
      });
    }
    if (column.columnName === "interval" && column.order === "ascending") {
      newArray.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
    }
    if (column.columnName === "interval" && column.order === "descending") {
      newArray.sort((a, b) => {
        return a.startDate < b.startDate ? 1 : -1;
      });
    }
    if (column.columnName === "pontat" && column.order === "ascending") {
      newArray.sort((a, b) => (a.text < b.text ? -1 : 1));
    }
    if (column.columnName === "pontat" && column.order === "descending") {
      newArray.sort((a, b) => {
        return a.text < b.text ? 1 : -1;
      });
    }
    if (column.columnName === "ore" && column.order === "ascending") {
      newArray.sort((a, b) =>
        a?.stringDates?.[0] < b.stringDates?.[0] ||
        (a.stringDates?.[0] === b.stringDates?.[0] &&
          a.stringDates?.[1] < b.stringDates?.[1])
          ? -1
          : 1
      );
    }
    if (column.columnName === "ore" && column.order === "descending") {
      newArray.sort((a, b) => {
        return a.stringDates?.[0] < b.stringDates?.[0] ||
          (a.stringDates?.[0] === b.stringDates?.[0] &&
            a.stringDates?.[1] < b.stringDates?.[1])
          ? 1
          : -1;
      });
    }
    // if (column.order === "ascending")
    //   newArray.sort((a, b) => a?.[column.columnName] - b?.[column.columnName]);
    // if (column.order === "ascending")
    //   newArray.sort((a, b) => b?.[column.columnName] - a?.[column.columnName]);
    // console.log(newArray);
    return newArray;
  };
  return (
    <div>
      <Space>
        <Button
          onClick={() => setProfesori(!profi)}
          style={{
            backgroundColor: profi === false ? "grey" : "#1677ff",
            color: "white",
          }}
        >
          Profesori
        </Button>
        <Button
          onClick={() => setAdministrator(!administrator)}
          style={{
            backgroundColor: administrator === false ? "grey" : "#1677ff",
            color: "white",
          }}
        >
          Administrare
        </Button>
        <Button
          onClick={() => setConcediuOdihna(!concediuOdihna)}
          style={{
            backgroundColor: concediuOdihna === false ? "grey" : "#1677ff",
            color: "white",
          }}
        >
          CONCEDIU ODIHNA
        </Button>
        <Button
          onClick={() => setConcediuMedical(!concediuMedical)}
          style={{
            backgroundColor: concediuMedical === false ? "grey" : "#1677ff",
            color: "white",
          }}
        >
          CONCEDIU MEDICAL
        </Button>
      </Space>
      <br />
      <br />
      <Select
        showSearch
        style={{ width: "200px" }}
        placeholder="Profesor"
        optionFilterProp="children"
        value={prof}
        onChange={setProf}
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={profesori.map((p) => {
          return {
            label: p.numeDeFamilie + " " + p.prenume,
            value: p.id,
          };
        })}
      />
      <RangePicker
        onChange={(e) => {
          let newDate = e?.[0].toDate();
          newDate.setHours(0, 0, 0, 0);
          setStartDate(newDate);
          newDate = e?.[1].toDate();
          newDate.setHours(23, 59, 59, 59);
          setEndDate(newDate);
        }}
      />
      <TimePicker.RangePicker
        format={format}
        defaultValue={[dayjs("00:00", format), dayjs("23:59", format)]}
        onChange={(_, stringDates) => {
          setStringDates(stringDates);
        }}
      />
      <Select
        value={text}
        onChange={setText}
        options={[
          { label: "CONCEDIU MEDICAL", value: "CONCEDIU MEDICAL" },
          { label: "CONCEDIU DE ODIHNA", value: "CONCEDIU" },
          { label: "NU PONTA", value: "NU PONTA" },
        ]}
      />

      <Button
        onClick={async () => {
          const id = Date.now().toString();
          await updateDocDatabase("concedii", id, {
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            prof,
            text,
            stringDates,
            id,
          });
        }}
      >
        Adauga
      </Button>
      <br />
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column.columnName === "profesor" ? column.order : null}
              onClick={() =>
                setColumn({
                  columnName: "profesor",
                  order:
                    column.order === "descending" ? "ascending" : "descending",
                })
              }
            >
              Profesor
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column.columnName === "interval" ? column.order : null}
              onClick={() =>
                setColumn({
                  columnName: "interval",
                  order:
                    column.order === "descending" ? "ascending" : "descending",
                })
              }
            >
              Interval
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column.columnName === "pontat" ? column.order : null}
              onClick={() =>
                setColumn({
                  columnName: "pontat",
                  order:
                    column.order === "descending" ? "ascending" : "descending",
                })
              }
            >
              Text Pontat
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column.columnName === "ore" ? column.order : null}
              onClick={() =>
                setColumn({
                  columnName: "ore",
                  order:
                    column.order === "descending" ? "ascending" : "descending",
                })
              }
            >
              Interval orar
            </Table.HeaderCell>
            <Table.HeaderCell>Sterge</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortColumns(concedii)?.map((c) => {
            if (
              profi === false &&
              profesori
                .find((p) => p.id === c.prof)
                ?.selectedMaterii?.find((m) => !m.includes("Admin"))
            ) {
              return <></>;
            }
            if (
              administrator === false &&
              profesori
                ?.find((p) => p.id === c.prof)
                ?.selectedMaterii?.find((m) => m.includes("Admin")) &&
              profesori.find((p) => p.id === c.prof)?.selectedMaterii
                ?.length === 1
            ) {
              return <></>;
            }
            if (concediuMedical === false && c.text === "CONCEDIU MEDICAL") {
              return <></>;
            }
            if (concediuOdihna === false && c.text === "CONCEDIU") {
              return <></>;
            }

            return (
              <Table.Row>
                <Table.Cell>
                  {profesori.find((p) => p.id === c.prof)?.numeDeFamilie +
                    " " +
                    profesori.find((p) => p.id === c.prof)?.prenume}
                </Table.Cell>
                <Table.Cell>
                  {" "}
                  {formatDate(c.startDate) + " - " + formatDate(c.endDate)}
                </Table.Cell>
                <Table.Cell> {c.text}</Table.Cell>
                <Table.Cell>
                  {" "}
                  {c.stringDates?.[0] || "00:00"} -{" "}
                  {c.stringDates?.[1] || "23:59"}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    danger
                    onClick={async () => {
                      await deleteDataDoc("concedii", c.id);
                    }}
                  >
                    Sterge
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

export default Concedii;
