import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Input, Button, Divider, Space, Radio, Tabs, Table } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { Checkbox } from "antd";
import { StepTitle } from "semantic-ui-react";
import { deleteDataDoc, getDataDoc, updateDocDatabase } from "../../database";
import { openErrorNotification } from "../../Components/Notifications/errorNotification";
import { openSuccesNotification } from "../../Components/Notifications/succesNotification";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Image } from "antd";
import ProfileImageAnonymus from "../../assets/profile-elev.webp";
import { Carousel } from "antd";
import { db } from "../../database/firebase";
import { collection, getDocs } from "firebase/firestore";
function Formular() {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const Intrebari = () => {
    const [intrebari, setIntrebari] = React.useState([]);
    const [title, setTitlu] = useState("");
    const [descriere, setDescriere] = useState("");
    const [anonim, setAnonym] = React.useState(true);

    const [publication, setPublic] = React.useState(false);
    const fetchData = async () => {
      let data = await getDataDoc("formulare", id);
      setTitlu(data.title || "");
      setDescriere(data.descriere || "");
      setIntrebari(data.intrebari || []);
      setAnonym(data.anonym || true);
      setPublic(data.publication || false);
    };
    useEffect(() => {
      fetchData();
    }, [id]);
    return (
      <div>
        <Input
          placeholder="Titlu Formular"
          value={title}
          key={"1"}
          style={{ width: "50%" }}
          onChange={(e) => {
            setTitlu(e.target.value);
          }}
        />
        <br />
        <br />
        <Input.TextArea
          placeholder="Descriere"
          style={{ width: "50%" }}
          autoSize={{ minRows: 3, maxRows: 100 }}
          value={descriere}
          onChange={(e) => {
            setDescriere(e.target.value);
          }}
        />
        <br />
        <br />

        <Checkbox
          checked={anonim}
          onChange={(e) => {
            setAnonym(e.target.checked);
          }}
        >
          Anonim
        </Checkbox>
        <Divider />
        <h3>Intrebari</h3>
        {intrebari.map((q, index) => {
          if (q.type === "radio")
            return (
              <>
                <Space>
                  <h3>Intrebarea {index + 1} </h3>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      let array = JSON.parse(JSON.stringify(intrebari));
                      array.splice(index, 1);
                      setIntrebari(array);
                    }}
                  />
                </Space>
                <br />
                <p>
                  Daca vei folosi doar cifre, programul va calcula si media
                  raspunsurilor
                </p>
                <Input
                  value={q.prompt}
                  onChange={(e) => {
                    let array = JSON.parse(JSON.stringify(intrebari));
                    array[index].prompt = e.target.value;
                    setIntrebari(array);
                  }}
                />
                <br />
                <br />
                <Button
                  onClick={() => {
                    let array = JSON.parse(JSON.stringify(intrebari));
                    array[index].variante = [...array[index].variante, ""];
                    setIntrebari(array);
                  }}
                >
                  {" "}
                  Adaugă Optiune
                </Button>
                <br />
                <br />
                <div style={{ background: "#f5f5f5" }}>
                  <br />
                  <Radio.Group>
                    {q.variante.map((v, vi) => (
                      <Radio value={v}>
                        <Space>
                          <Input
                            value={v}
                            onChange={(e) => {
                              let array = JSON.parse(JSON.stringify(intrebari));
                              array[index].variante[vi] = e.target.value;
                              setIntrebari(array);
                            }}
                          />
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              let array = JSON.parse(JSON.stringify(intrebari));
                              array[index].variante = array[
                                index
                              ].variante.filter((x) => x !== v);
                              setIntrebari(array);
                            }}
                          />
                        </Space>
                      </Radio>
                    ))}
                  </Radio.Group>{" "}
                  <br />
                  <br />
                </div>
                <br />
                <br />
                <Divider />
              </>
            );
          if (q.type === "text")
            return (
              <>
                <Space>
                  <h3>Intrebarea {index + 1} </h3>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      let array = JSON.parse(JSON.stringify(intrebari));
                      array.splice(index, 1);
                      setIntrebari(array);
                      e.preventDefault();
                    }}
                  />
                </Space>
                <br />
                <Input
                  value={q.prompt}
                  onChange={(e) => {
                    let array = JSON.parse(JSON.stringify(intrebari));
                    array[index].prompt = e.target.value;
                    setIntrebari(array);
                  }}
                />
                <br />
                <br />

                <br />
                <br />
                <div style={{ background: "#f5f5f5" }}>
                  <Input.TextArea
                    disabled
                    placeholder="Aici vor scrie elevii raspunsul"
                  />
                </div>
                <br />
                <br />
                <Divider />
              </>
            );
        })}
        <Space>
          <Button
            onClick={() => {
              setIntrebari([
                ...intrebari,
                {
                  type: "radio",
                  prompt: "Completeaza Promptul",
                  variante: [],
                  id: Date.now().toString(),
                },
              ]);
            }}
          >
            Radio
          </Button>
          <Button
            onClick={() =>
              setIntrebari([
                ...intrebari,
                {
                  type: "text",
                  prompt: "Completeaza Promptul",
                  id: Date.now().toString(),
                },
              ])
            }
          >
            Text
          </Button>
        </Space>
        <br />
        <br />
        <Button
          type="primary"
          onClick={async () => {
            try {
              if (publication)
                throw new Error("Formularul a fost publicat deja");
              await updateDocDatabase("formulare", id, {
                title,
                descriere,
                intrebari,
                anonim,
              });
              openSuccesNotification("Formularul a fost salvat");
            } catch (e) {
              openErrorNotification(e.message);
            }
          }}
        >
          {" "}
          Salveaza{" "}
        </Button>
      </div>
    );
  };
  const Setari = () => {
    const [publication, setPublic] = React.useState(false);
    const fetchData = async () => {
      let data = await getDataDoc("formulare", id);

      setPublic(data.publication || false);
    };
    useEffect(() => {
      fetchData();
    }, [id]);
    return (
      <>
        <Space>
          {" "}
          <Button
            danger
            onClick={async () => {
              await deleteDataDoc("formulare", id);
              navigate("/feedback");
            }}
          >
            Sterge Fromular
          </Button>
          {publication === false ? (
            <Button
              type="primary"
              onClick={async () => {
                await updateDocDatabase("formulare", id, {
                  publication: true,
                });
                setPublic(true);
                openSuccesNotification("Formular public");
              }}
            >
              Publica
            </Button>
          ) : (
            <Button
              style={{ background: "purple", color: "white" }}
              onClick={async () => {
                await updateDocDatabase("formulare", id, {
                  publication: false,
                });
                setPublic(false);
                openSuccesNotification("Formular retras");
              }}
            >
              Retrage Publicarea
            </Button>
          )}
        </Space>
      </>
    );
  };
  const Rezultate = () => {
    const profesori = useSelector((state) => state.profesori);
    const user = useSelector((state) => state.user);
    const [profesoriDisplay, setProfesoriDisplay] = useState([]);
    const customeSlider = React.useRef();
    const navigate = useNavigate();
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [formular, setFormular] = useState();
    const [profesorData, setProfesorData] = useState();
    const clase = useSelector((state) => state.clase);
    const [inputValue, setInputValue] = useState("");
    const [raspunsuriElevi, setRaspunsuriElev] = useState([]);
    let inputHandler = (e) => {
      //convert input text to lower case
      var lowerCase = e.target?.value.toLowerCase();
      setInputValue(lowerCase);
    };
    const fetchData = async () => {
      if (selectedTeacher) {
        let data = await getDataDoc("profesori", selectedTeacher || "");
        setProfesorData({
          ...data,
          ore: (data?.ore || []).map((el) => {
            return {
              ...el,
              startDate: new Date(el.startDate),
              endDate: new Date(el.endDate),
            };
          }),
        });
      }
      const querySnapshot = await getDocs(
        collection(
          db,
          id + "prof" + selectedTeacher + "materie" + selectedMaterie
        )
      );

      let array = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        array.push({
          ...doc.data(),
          startDate: new Date(doc.data().startDate),
          endDate: new Date(doc.data().endDate),
        });
      });
      setRaspunsuriElev(array);

      let data = await getDataDoc("formulare", id);
      setFormular(data);
    };
    useEffect(() => {
      setProfesoriDisplay([]);
      let array = profesori.filter(
        (p) => p.id === user.uid || user.type === "admin"
      );
      setProfesoriDisplay(
        array.filter((el) => {
          if (inputValue === "") {
            return el;
          } else {
            let materiiName = "";
            materii.forEach((materie) => {
              if (el.selectedMaterii.includes(materie.id)) {
                materiiName += materie.numeMaterie + " " + materie.profil + " ";
              }
            });
            return (
              el.prenume +
              " " +
              el.numeDeFamilie +
              " " +
              el.cnp +
              " " +
              materiiName
            )
              .toLowerCase()
              .includes(inputValue);
          }
        })
      );
    }, [profesori, inputValue]);
    useEffect(() => {
      fetchData();
    }, [selectedTeacher]);
    const materii = useSelector((state) => state.materii);
    const [selectedMaterie, setSelectedMaterie] = useState("");
    const props = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };
    return (
      <>
        {selectedTeacher === null ? (
          <div>
            <h1 style={{ fontSize: "30px" }}>Profesorii</h1>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2vw",
                paddingTop: "2vh",
              }}
            >
              <Input
                placeholder="Introdu nume sau materie"
                onChange={inputHandler}
              />
            </div>
            <br />
            <br />
            {materii.map((mat) => {
              if (
                profesoriDisplay.filter((prof) =>
                  prof?.selectedMaterii?.includes(mat.id)
                ).length > 0
              )
                return (
                  <>
                    <div
                      style={{
                        backgroundColor: "#edf4f5",
                        width: "100%",
                        border: "1px solid #002140",
                      }}
                    >
                      <br />
                      <h1>{mat.numeMaterie + " - " + mat.profil} </h1>
                      <br />
                      <div className="cards-profiles">
                        {profesoriDisplay
                          .filter((prof) =>
                            prof?.selectedMaterii?.includes(mat.id)
                          )
                          .map((prof, index) => {
                            return (
                              <div
                                piled
                                className="card-profile"
                                onClick={() => {
                                  setSelectedTeacher(prof.id);
                                  setSelectedMaterie(mat.id);
                                }}
                              >
                                {prof.text}
                              </div>
                            );
                          })}
                      </div>

                      <br />
                    </div>
                    <br />
                  </>
                );
            })}
          </div>
        ) : (
          <>
            {" "}
            <Image
              src={profesorData?.photoLink || ProfileImageAnonymus}
              height={200}
              style={{ marginTop: "5px" }}
            />
            <h1>{profesorData?.numeDeFamilie + " " + profesorData?.prenume}</h1>
            <h3>
              Materia selectata: &nbsp;&nbsp;
              {materii.find((m) => m.id === selectedMaterie).numeMaterie}&nbsp;
              {materii.find((m) => m.id === selectedMaterie).profil}
            </h3>
            {(formular?.intrebari || []).map((q, index) => {
              if (q.type === "radio") {
                const columns = [
                  {
                    title: "Intrebarea",
                    dataIndex: "intrebarea",
                    key: "intrebarea",
                    render: (text) => <a>{text}</a>,
                  },
                  ...q.variante.map((e) => {
                    return {
                      title: e,
                      dataIndex: e,
                      key: e,
                      render: (text) => <a>{text}</a>,
                    };
                  }),
                  (q.variante || []).filter((v) => isNaN(v)).length === 0
                    ? {
                        title: "Medie",
                        dataIndex: "medie",
                        key: "medie",
                        render: (text) => <a>{text}</a>,
                      }
                    : {},
                ];

                return (
                  <>
                    <h3>Intrebarea {index + 1} </h3>
                    <Table
                      columns={columns}
                      pagination={false}
                      dataSource={[
                        {
                          intrebarea: q.prompt,
                          ...(q?.variante || []).reduce((acc, cur) => {
                            return {
                              [cur]:
                                (raspunsuriElevi || []).filter(
                                  (e) => e.raspunsuri[index] === cur
                                )?.length || 0,
                              ...acc,
                            };
                          }, {}),
                          ...((q.variante || []).filter((v) => isNaN(v))
                            .length === 0
                            ? {
                                medie: (
                                  (q?.variante || []).reduce((acc, cur) => {
                                    return (
                                      acc +
                                      ((raspunsuriElevi || []).filter(
                                        (e) => e.raspunsuri[index] === cur
                                      )?.length || 0) *
                                        parseInt(cur)
                                    );
                                  }, 0) /
                                  (raspunsuriElevi?.length === undefined ||
                                  raspunsuriElevi?.length === 0
                                    ? 1
                                    : raspunsuriElevi?.length)
                                ).toFixed(2),
                              }
                            : {}),
                        },
                      ]}
                    />
                  </>
                );
              }
            })}
            <h2>Raspunsuri</h2>
            <Carousel dotPosition="bottom" {...props} ref={customeSlider}>
              {raspunsuriElevi.map((r) => {
                return (
                  <div>
                    <div
                      style={{
                        textAlign: "center",
                        backgroundColor: "#f5f5f5f5",
                      }}
                    >
                      <br />
                      <Space style={{ color: "black" }}>
                        <StepBackwardOutlined
                          onClick={() => {
                            customeSlider?.current?.prev();
                          }}
                        />{" "}
                        <StepForwardOutlined
                          onClick={() => customeSlider?.current?.next()}
                        />
                      </Space>
                      {!formular.anonim === true && <h3>{r.nume}</h3>}
                      <h4>
                        Clasa:{" "}
                        {clase.find((c) => c.id === r.clasa).anClasa +
                          clase.find((c) => c.id === r.clasa).identificator}
                      </h4>
                      {(formular?.intrebari || []).map((q, index) => {
                        if (q.type === "radio")
                          return (
                            <>
                              <br />
                              <h3>Intrebarea {index + 1} </h3>
                              <h5>{q.prompt}</h5>
                              <Radio.Group value={r.raspunsuri[index]}>
                                {q.variante.map((v, vi) => (
                                  <Radio value={v}>{v}</Radio>
                                ))}
                              </Radio.Group>{" "}
                              <Divider />
                            </>
                          );
                        if (q.type === "text")
                          return (
                            <>
                              <h3>Intrebarea {index + 1} </h3>

                              <h5>{q.prompt}</h5>

                              <Input.TextArea
                                placeholder="Scrie raspunsul tau"
                                value={r.raspunsuri[index]}
                              />

                              <Divider />
                            </>
                          );
                      })}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </>
        )}
      </>
    );
  };
  const items = [
    user.type === "admin" && {
      key: "1",
      label: `Structura`,
      children: <Intrebari />,
    },
    {
      key: "2",
      label: `Rezultate`,
      children: <Rezultate />,
    },
    user.type === "admin" && {
      key: "3",
      label: `Setari`,
      children: <Setari />,
    },
  ];

  return (
    <Tabs defaultActiveKey={user.type === "admin" ? "1" : "2"} items={items} />
  );
}

export default Formular;
