import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getDataDoc, updateDocDatabase } from "../../database";
import { openSuccesNotification } from "../../Components/Notifications/succesNotification";
import { useSelector } from "react-redux";
import { Divider, Radio, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { openErrorNotification } from "../../Components/Notifications/errorNotification";
function RaspuneFormular() {
  const { id } = useParams();
  const [formular, setFormular] = useState();
  const [searchParams] = useSearchParams();
  const materieId = searchParams.get("materie");
  const profesor = searchParams.get("prof");
  const profesori = useSelector((state) => state.profesori);
  const materii = useSelector((state) => state.materii);
  const [raspunsuri, setRaspunsuri] = useState([]);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const fetchData = async () => {
    let data = await getDataDoc("formulare", id);
    let data2 = await getDataDoc(
      id + "prof" + profesor + "materie" + materieId,
      user.uid
    );
    setRaspunsuri(data2?.raspunsuri || data.intrebari.map((e) => ""));
    setFormular(data);
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div>
      <h1>{formular?.title}</h1>
      <br />
      <textarea
        style={{ width: "80%", textAlign: "center" }}
        value={formular?.descriere}
      />
      <br />
      {formular?.anonim === true ? (
        <p>Formularul este anonim, numele tau va ramane confidential</p>
      ) : (
        <p>Formularul este public, numele tau va fi expus</p>
      )}
      <h5>
        Profesorul selectat:{" "}
        {profesori.find((p) => p.id === profesor)?.numeDeFamilie +
          " " +
          profesori.find((p) => p.id === profesor)?.prenume}{" "}
      </h5>
      <h5>
        Materia selectata:{" "}
        {materii.find((p) => p.id === materieId)?.numeMaterie}
      </h5>
      <Divider />
      {(formular?.intrebari || []).map((q, index) => {
        if (q.type === "radio")
          return (
            <>
              <h3>Intrebarea {index + 1} </h3>
              <h5>{q.prompt}</h5>
              <Radio.Group
                onChange={(e) => {
                  let array = JSON.parse(JSON.stringify(raspunsuri));
                  array[index] = e.target.value;
                  setRaspunsuri(array);
                }}
                value={raspunsuri[index]}
              >
                {q.variante.map((v, vi) => (
                  <Radio value={v}>{v}</Radio>
                ))}
              </Radio.Group>{" "}
              <Divider />
            </>
          );
        if (q.type === "text")
          return (
            <>
              <h3>Intrebarea {index + 1} </h3>

              <h5>{q.prompt}</h5>

              <Input.TextArea
                placeholder="Scrie raspunsul tau"
                value={raspunsuri[index]}
                onChange={(e) => {
                  let array = JSON.parse(JSON.stringify(raspunsuri));
                  array[index] = e.target.value;
                  setRaspunsuri(array);
                }}
              />

              <Divider />
            </>
          );
      })}
      <Button
        type="primary"
        onClick={async () => {
          try {
            await updateDocDatabase(
              id + "prof" + profesor + "materie" + materieId,
              user.uid,
              {
                clasa: user?.clasaMea?.id || "faraclasa",
                raspunsuri: raspunsuri,
                nume: user.numeDeFamilie + " " + user.prenume,
                materie: materieId,
              }
            );
            openSuccesNotification("Trimis");
            navigate("/elev-profesori");
          } catch (e) {
            openErrorNotification(e.message);
          }
        }}
      >
        Salveaza
      </Button>
    </div>
  );
}

export default RaspuneFormular;
