import React, { useEffect, useState, useRef } from "react";
import { Space, Table, DatePicker, Button } from "antd";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../database/firebase";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const CondicaExport = ({ startDate, endDate, print = false, condicaProfi }) => {
  const [condica, setCondica] = useState([]);
  const componentRef = useRef();
  const [concedii, setConcedii] = useState([]);
  const profesori = useSelector((state) => state.profesori);
  const materii = useSelector((state) => state.materii);
  const clase = useSelector((state) => state.clase);
  const [semnaturi, setSemnaturi] = useState({});
  useEffect(() => {
    let sem = {};
    const fetchSemnaturi = async () => {
      for await (let prof of profesori) {
        if (prof.semnatura) {
          let semnaturaLink = prof.semnatura;
          const response = await fetch(semnaturaLink);
          const blob = await response.blob();

          // Creează un URL local din blob și setează-l în state
          const localUrl = URL.createObjectURL(blob);
          sem[prof.id] = localUrl;
        } else sem[prof.id] = "prezent";
      }
      setSemnaturi(sem);
    };
    fetchSemnaturi();
  }, [profesori]);
  const retriveHourCorectly = (date, type) => {
    if (type === "start") {
      if (date.getHours() < 10) return "0" + date.getHours() + ":" + "00";
      else return date.getHours() + ":10";
    }
    if (type === "end") {
      if (date.getHours() < 10) return "0" + date.getHours() + ":" + "50";
      else return date.getHours() + ":00";
    }
  };
  const fetchDataForMonth = async (year, month) => {
    let array = [];
    for await (let profesorData of profesori) {
      const querySnapshot = await getDocs(
        collection(db, profesorData.id + "condica" + month + "an" + year)
      );

      querySnapshot.forEach((doc) => {
        array.push({
          ...doc.data(),
          uuid: doc.id,
          startDate: new Date(doc.data().startDate),
          endDate: new Date(doc.data().endDate),
          displayName: profesorData.numeDeFamilie + " " + profesorData.prenume,
          profId: profesorData.id,
        });
      });
    }
    setCondica((prevCondica) => [...prevCondica, ...array]);
  };

  const fetchAllData = async () => {
    let current = dayjs(startDate);

    while (current.isBefore(endDate) || current.isSame(endDate, "month")) {
      await fetchDataForMonth(current.year(), current.toDate().getMonth());
      current = current.add(1, "month");
    }

    const querySnapshotConcedii = await getDocs(collection(db, "concedii"));
    let arrayNou = [];
    querySnapshotConcedii.forEach((doc) => {
      arrayNou.push({
        ...doc.data(),
        startDate: new Date(doc.data().startDate),
        endDate: new Date(doc.data().endDate),
        id: doc.id,
      });
    });
    setConcedii(arrayNou);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setCondica([]); // Resetează condica pentru a evita duplicările
      fetchAllData();
    }
  }, [startDate, endDate]);

  const generateDates = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const zile = generateDates(new Date(startDate), new Date(endDate));

  const formatterLuna = new Intl.DateTimeFormat("ro-RO", { month: "long" });

  const concediu = (data, standalones) => {
    if (typeof data === "number" || typeof data === "string")
      data = new Date(data);

    if (
      (concedii || []).find(
        (c) =>
          c.prof === data.profId &&
          c.startDate.getTime() <= data &&
          c.endDate.getTime() >= data &&
          (!standalones.getHours ||
            (retriveHourCorectly(standalones, "start") >=
              (c.stringDates?.[0] || "00:00") &&
              retriveHourCorectly(standalones, "start") <=
                (c.stringDates?.[1] || "23:59")))
      )?.text === "CONCEDIU MEDICAL"
    )
      return { color: "red" };
    if (
      (concedii || []).find(
        (c) =>
          c.prof === data.profId &&
          c.startDate.getTime() <= data &&
          c.endDate.getTime() >= data &&
          (!data.getHours ||
            (retriveHourCorectly(data, "start") >=
              (c.stringDates?.[0] || "00:00") &&
              retriveHourCorectly(data, "start") <=
                (c.stringDates?.[1] || "23:59")))
      )?.text === "CONCEDIU"
    )
      return { color: "green" };
    return {};
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "Data",
      key: "data",
      render: (e, data) => {
        if (data.zi)
          return (
            <p
              style={{
                ...concediu(data.zi, data.startDate),
              }}
            >
              {new Date(data.zi).toLocaleDateString("ro-RO")}
            </p>
          );
        return (
          <p
            style={{
              ...concediu(data.startDate, data.startDate),
            }}
          >
            {data.startDate.toLocaleDateString("ro-RO")}
          </p>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Interval",
      dataIndex: "interval",
      key: "interval",
      render: (e, data) => {
        if (data.tip === "toataziua")
          return <p style={concediu(data.zi, data.startDate)}>-</p>;
        return (
          <p
            style={{
              ...concediu(data.zi || data.startDate, data.startDate),
            }}
          >
            {retriveHourCorectly(data.startDate, "start")} -
            {retriveHourCorectly(data.endDate, "end")}{" "}
          </p>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Clasa",
      dataIndex: "Clasa",
      key: "clasa",
      render: (e, data) => {
        if (data.tip === "toataziua") return <p>-</p>;
        return (
          <>
            {data.tip === "privat" ? (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                -
              </p>
            ) : (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                {clase.find((cls) => cls.id === data.classId)?.anClasa +
                  clase.find((cls) => cls.id === data.classId)?.identificator}
              </p>
            )}
          </>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Disciplina",
      dataIndex: "Materie",
      key: "Materie",
      render: (e, data) => {
        return (
          <>
            {data.tip === "privat" ? (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                {data.textOra || data.classId || data.materieId}
              </p>
            ) : (
              <p style={concediu(data.zi || data.startDate, data.startDate)}>
                {materii.find((m) => m.id === data.materieId)?.numeMaterie}
              </p>
            )}
          </>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Nume și prenume",
      dataIndex: "prof",
      key: "prof",
      render: (e, data) => {
        return (
          <>
            <p style={concediu(data.zi || data.startDate, data.startDate)}>
              {data.displayName}
            </p>
          </>
        );
      },
      responsive: ["sm"],
    },

    {
      title: "Semnătură",
      key: "tip",
      render: (_, data) => {
        if (semnaturi[data.profId] === "prezent")
          return (
            <p
              style={{ ...concediu(data.zi || data.startDate, data.startDate) }}
            >
              {concedii?.find(
                (c) =>
                  c.prof === data.profId &&
                  c.startDate.getTime() <=
                    (data.zi || data.startDate?.getTime()) &&
                  c.endDate.getTime() >=
                    (data.zi || data.startDate?.getTime()) &&
                  (!data.startDate.getHours ||
                    (retriveHourCorectly(data.startDate, "start") >=
                      (c.stringDates?.[0] || "00:00") &&
                      retriveHourCorectly(data.startDate, "start") <=
                        (c.stringDates?.[1] || "23:59")))
              )?.text || "prezent"}
            </p>
          );
        else {
          if (
            Object.keys(concediu(data.zi || data.startDate, data.startDate))
              .length > 0
          ) {
            return (
              <p
                style={{
                  ...concediu(data.zi || data.startDate, data.startDate),
                }}
              >
                {concedii?.find(
                  (c) =>
                    c.prof === data.profId &&
                    c.startDate.getTime() <=
                      (data.zi || data.startDate?.getTime()) &&
                    c.endDate.getTime() >=
                      (data.zi || data.startDate?.getTime()) &&
                    (!data.startDate.getHours ||
                      (retriveHourCorectly(data.startDate, "start") >=
                        (c.stringDates?.[0] || "00:00") &&
                        retriveHourCorectly(data.startDate, "start") <=
                          (c.stringDates?.[1] || "23:59")))
                )?.text || "prezent"}
              </p>
            );
          } else
            return (
              <img
                src={semnaturi[data.profId]}
                style={{ width: "100px", height: "30px" }}
              />
            );
        }
      },
      responsive: ["sm"],
    },
  ];
  const columnsAdmin = [
    {
      title: "Data",
      dataIndex: "Data",
      key: "data",
      render: (e, data) => {
        if (data.zi)
          return (
            <p
              style={{
                ...concediu(data.zi, data.startDate),
              }}
            >
              {new Date(data.zi).toLocaleDateString("ro-RO")}
            </p>
          );
        return (
          <p
            style={{
              ...concediu(data.startDate, data.startDate),
            }}
          >
            {data.startDate.toLocaleDateString("ro-RO")}
          </p>
        );
      },
      responsive: ["sm"],
    },

    {
      title: "Nume și prenume",
      dataIndex: "prof",
      key: "prof",
      render: (e, data) => {
        return (
          <>
            <p style={concediu(data.zi || data.startDate, data.startDate)}>
              {data.displayName}
            </p>
          </>
        );
      },
      responsive: ["sm"],
    },

    {
      title: "Semnătură",
      key: "tip",
      render: (_, data) => {
        if (semnaturi[data.profId] === "prezent")
          return (
            <p
              style={{ ...concediu(data.zi || data.startDate, data.startDate) }}
            >
              {concedii?.find(
                (c) =>
                  c.prof === data.profId &&
                  c.startDate.getTime() <=
                    (data.zi || data.startDate?.getTime()) &&
                  c.endDate.getTime() >=
                    (data.zi || data.startDate?.getTime()) &&
                  (!data.startDate.getHours ||
                    (retriveHourCorectly(data.startDate, "start") >=
                      (c.stringDates?.[0] || "00:00") &&
                      retriveHourCorectly(data.startDate, "start") <=
                        (c.stringDates?.[1] || "23:59")))
              )?.text || "prezent"}
            </p>
          );
        else {
          if (
            Object.keys(concediu(data.zi || data.startDate, data.startDate))
              .length > 0
          ) {
            return (
              <p
                style={{
                  ...concediu(data.zi || data.startDate, data.startDate),
                }}
              >
                {concedii?.find(
                  (c) =>
                    c.prof === data.profId &&
                    c.startDate.getTime() <=
                      (data.zi || data.startDate?.getTime()) &&
                    c.endDate.getTime() >=
                      (data.zi || data.startDate?.getTime()) &&
                    (!data.startDate.getHours ||
                      (retriveHourCorectly(data.startDate, "start") >=
                        (c.stringDates?.[0] || "00:00") &&
                        retriveHourCorectly(data.startDate, "start") <=
                          (c.stringDates?.[1] || "23:59")))
                )?.text || "prezent"}
              </p>
            );
          } else
            return (
              <img
                src={semnaturi[data.profId]}
                style={{ width: "100px", height: "30px" }}
              />
            );
        }
      },
      responsive: ["sm"],
    },
  ];

  if (condicaProfi)
    return (
      <>
        {zile.map((current, index) => {
          if (current.getDay() == 6 || current.getDay() == 0) return <></>;
          else
            return (
              <div
                key={index}
                className="print"
                style={{ pageBreakAfter: "always", textAlign: "center" }}
              >
                <br />
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <p>Ziua: {current.getDate()}</p>
                  <p>Luna: {formatterLuna.format(current)}</p>
                  <p>Anul: {current.getFullYear()}</p>
                </div>
                <br />
                <div ref={componentRef}>
                  <Table
                    columns={columns}
                    dataSource={condica
                      .filter((c) => !c?.materieId?.includes("Admin"))
                      .filter(
                        (c) =>
                          current.getDate() ===
                            new Date(c.zi || c.startDate).getDate() &&
                          current.getMonth() ===
                            new Date(c.zi || c.startDate).getMonth() &&
                          current.getFullYear() ===
                            new Date(c.zi || c.startDate).getFullYear()
                      )
                      .sort((a, b) => a.startDate - b.startDate)}
                    pagination={false}
                  />
                </div>
                <br />
              </div>
            );
        })}
      </>
    );
  else
    return (
      <>
        {zile.map((current, index) => {
          if (current.getDay() == 6 || current.getDay() == 0) return <></>;
          else
            return (
              <div
                key={index}
                className="print"
                style={{ pageBreakAfter: "always", textAlign: "center" }}
              >
                <br />
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <p>Ziua: {current.getDate()}</p>
                  <p>Luna: {formatterLuna.format(current)}</p>
                  <p>Anul: {current.getFullYear()}</p>
                </div>
                <br />
                <div ref={componentRef}>
                  <Table
                    columns={columnsAdmin}
                    dataSource={condica
                      .filter((c) => c?.materieId?.includes("Admin"))
                      .filter(
                        (c) =>
                          current.getDate() ===
                            new Date(c.zi || c.startDate).getDate() &&
                          current.getMonth() ===
                            new Date(c.zi || c.startDate).getMonth() &&
                          current.getFullYear() ===
                            new Date(c.zi || c.startDate).getFullYear()
                      )
                      .sort((a, b) => a.startDate - b.startDate)}
                    pagination={false}
                  />
                </div>
                <br />
              </div>
            );
        })}
      </>
    );
};

export default CondicaExport;
