import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { getDocs, collection, onSnapshot } from "firebase/firestore";
import { db } from "../../database/firebase";
import { updateDocDatabase } from "../../database";
import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import userEvent from "@testing-library/user-event";
function Feedback() {
  const [formulare, setFormulare] = useState([]);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "formulare"));

    let array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      array.push({
        ...doc.data(),
      });
    });

    setFormulare(
      array.sort((a, b) => {
        if (a.date < b.date) return 1;
        else return -1;
      })
    );
  };
  useEffect(() => {
    fetchData();
    const unsub3 = onSnapshot(collection(db, "formulare"), (doc) => {
      fetchData();
    });
  }, []);

  return (
    <div>
      {user.type === "admin" && (
        <Button
          onClick={async () => {
            let id = Date.now().toString();
            await updateDocDatabase("formulare", id, {
              id,
              title: "Formular Nou " + id,
              date: Date.now().toString(),
            });
          }}
        >
          Adaugă Formular Nou
        </Button>
      )}

      <br />
      <br />
      <Row gutter={[30, 30]}>
        {(formulare || [])
          .filter((f) => f?.publication === true || user.type == "admin")
          ?.map((el) => (
            <Col xs={20} sm={20} md={10} lg={10} xl={6}>
              <Button
                onClick={() => {
                  navigate("/formular/" + el.id);
                }}
              >
                {el.title}
              </Button>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default Feedback;
